<template>
  <div class="bg-shop-summary">
    <el-card shadow="hover" class="x-card-title-slot mb-3">
      <div class="row no-gutters align-items-center" slot="header">
        <div class="col-auto header-title">
          用户数据
        </div>
        <div class="col-auto">起止日期：</div>
        <div class="col-auto mr-3">
          <el-date-picker
            style="width: 220px"
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            v-model="daterangeDate"
            type="daterange"
            @change="daterangeDateChange"
            range-separator="-"
            size="medium"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>

        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>

        <div class="col"></div>

        <el-button type="text" :loading="importLoading" @click="downZd()">导出当前数据</el-button>
      </div>

      <div class="btnTab">
        <el-button
          :type="type === item.value ? 'primary' : 'default'"
          @click="type = item.value"
          v-for="(item,index) in typeList" :key="index">
          {{ item.label }}
        </el-button>
      </div>

      <div id="priceList" :style="{ height: '60vh' }"></div>
    </el-card>


    <el-card shadow="hover" class="x-card-title-slot mb-3">
      <div class="row no-gutters align-items-center" slot="header">
        <div class="col-auto header-title">
          访客占比
        </div>
        <div class="col-auto">起止日期：</div>
        <div class="col-auto mr-3">
          <el-date-picker
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            style="width: 220px"
            v-model="daterangeDate1"
            type="daterange"
            size="medium"
            @change="daterangeDateChange1"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>

        <el-button type="primary" size="small" @click="handleSearch1()">搜索</el-button>

        <div class="col"></div>

        <el-button type="text" :loading="importLoading1" @click="downZd1()">导出当前数据</el-button>
      </div>

      <div class="btnTab">
        <el-button
          :type="type === item.value ? 'primary' : 'default'"
          @click="type = item.value"
          v-for="(item,index) in typeList" :key="index">
          {{ item.label }}
        </el-button>
      </div>

      <div id="topPie" :style="{ height: '60vh' }"></div>
    </el-card>
  </div>
</template>

<script>
import * as API_buyAnyalysis from '@/api/buyAnyalysis'
import {CategoryPicker} from '@/components'
import {handleDownload} from '@/utils'

export default {
  name: 'buyAnalysis',
  components: {
    [CategoryPicker.name]: CategoryPicker
  },
  data() {
    return {
      type: 1,
      typeList: [
        // { label: '新增', value: 1 },
        // { label: '累计', value: 4 },
      ],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近7日',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近15日',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近30日',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      importLoading1: false,

      daterangeDate: '',
      daterangeDate1: '',

      /** 图表参数*/
      params: {},
      params1: {},

      data: {},
      data1: {},

      tableHeight: document.body.clientHeight / 2
    }
  },
  created() {
    this.resetTimeParams();

    this.GET_PriceStatistics()
    this.GET_PriceStatistics1()
  },
  mounted() {
    window.onresize = this.countTableHeight
    this.$nextTick(() => {
      this.priceListChart = this.$echarts.init(document.getElementById('priceList'))
      this.topPie = this.$echarts.init(document.getElementById('topPie'))
    })
  },
  methods: {
    resetTimeParams(type) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);

      if (type === 1 || type === undefined) {
        this.params.start_time = parseInt(start / 1000)
        this.params.end_time = parseInt(end / 1000)
      }

      if (type === 2 || type === undefined) {
        this.params1.start_time = parseInt(start / 1000)
        this.params1.end_time = parseInt(end / 1000)
      }
    },
    downZd() {
      let data = {...this.data}
      this.importLoading = true
      let response = []
      data.xAxis.forEach(item => {
        response.push({date: item})
      })
      data.series.data.forEach((item, index) => {
        response[index]['xz'] = item
      })
      let tHeaders = ['日期', '新增会员数量']
      let filterVals = ['date', 'xz']
      handleDownload(response, tHeaders, filterVals, '用户数据')
      this.importLoading = false
    },
    downZd1() {
      let data = {...this.data1}
      this.importLoading1 = true
      let response = []
      data.xAxis.forEach(item => {
        response.push({date: item})
      })
      data.series.data.forEach((item, index) => {
        response[index]['xz'] = item
      })
      let tHeaders = ['日期', '访客占比']
      let filterVals = ['date', 'xz']
      handleDownload(response, tHeaders, filterVals, '访客占比')
      this.importLoading1 = false
    },
    daterangeDateChange(val) {
      if (val) {
        this.params.start_time = parseInt(val[0] / 1000);
        this.params.end_time = parseInt(val[1] / 1000);
      } else {
        this.resetTimeParams(1);
      }
      this.GET_PriceStatistics();
    },
    daterangeDateChange1(val) {
      if (val) {
        this.params1.start_time = parseInt(val[0] / 1000);
        this.params1.end_time = parseInt(val[1] / 1000);
      } else {
        this.resetTimeParams(2);
      }
      this.GET_PriceStatistics1();
    },
    /** 窗口缩放时计算table高度 */
    countTableHeight() {
      this.tableHeight = document.body.clientHeight / 2
      /** 图表刷新 */
      setTimeout(this.priceListChart.resize)
      setTimeout(this.topPie.resize)
    },

    /** 执行搜索 */
    handleSearch() {
      this.GET_PriceStatistics()
    },
    /** 执行搜索 */
    handleSearch1() {
      this.GET_PriceStatistics1()
    },
    GET_PriceStatistics() {
      API_buyAnyalysis.increaseMember(this.params).then(response => {
        this.data = response

        // // 临时数据
        // response.series.data = [1,3,4,5,2,3,1,4]
        // 临时数据

        this.priceListChart.setOption({
          tooltip: {trigger: 'axis'},
          grid: {
            x: '50px',
            y: '80px',
            x2: '60px',
            y2: '30px',
          },
          legend: {
            show: true,
            data: [{name: '新增会员数量'}],
            top: '10px'
          },
          // color: ['#7CB5EC'],
          toolbox: {
            feature: {
              magicType: {type: ['line', 'bar']},
              restore: {},
              saveAsImage: {}
            }
          },
          xAxis: {
            name: '日期',
            type: 'category',
            boundaryGap: false,
            data: response.xAxis
          },
          yAxis: {
            name: '新增会员数量',
            type: 'value',
            axisLabel: {
              formatter: '{value}'
            }
          },
          series: [
            {
              type: 'line',
              data: response.series.data,
              name: '新增会员数量',
              smooth: true,
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2,
                    color: '#1a43a9'
                  },
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#c2bbfe'
                    },
                    {
                      offset: 1,
                      color: '#d3edfb'
                    }
                  ]),
                },
              },
              areaStyle: {normal: {}},
              symbolSize: 0,
              barWidth: 50,
            }
          ]
        })
      })
    },
    GET_PriceStatistics1() {
      API_buyAnyalysis.orderVisitRadio(this.params1).then(response => {
        this.data1 = response

        // // 临时数据
        // response.series.data = [2,3,1,2,7,2,1,0]
        // 临时数据

        this.topPie.setOption({
          tooltip: {trigger: 'axis'},
          grid: {
            x: '50px',
            y: '80px',
            x2: '60px',
            y2: '30px',
          },
          legend: {
            show: true,
            data: [{name: '访客占比'}],
            top: '10px'
          },
          color: ['#7CB5EC'],
          toolbox: {
            feature: {
              magicType: {type: ['line', 'bar']},
              restore: {},
              saveAsImage: {}
            }
          },
          xAxis: {
            name: '日期',
            type: 'category',
            boundaryGap: false,
            data: response.xAxis
          },
          yAxis: {
            name: '访客占比',
            type: 'value',
            axisLabel: {
              formatter: '{value}'
            }
          },
          series: [
            {
              type: 'line',
              data: response.series.data,
              name: '访客占比',
              smooth: true,
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2,
                    color: '#1a43a9'
                  },
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#c2bbfe'
                    },
                    {
                      offset: 1,
                      color: '#d3edfb'
                    }
                  ]),
                },
              },
              areaStyle: {normal: {}},
              symbolSize: 0,
              barWidth: 50,
            },
          ]
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

